.menuContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* align-items: center; */
  column-gap: 9em;
  padding-top: 2em;
  color: antiquewhite;
  font-weight: bold;
  font-size: 1.1em;
  font-style: italic;
  align-items: center;
}

@media only screen and (max-width: 1700px) {
  
  .menuContainer{
    column-gap: 8em;
    font-size: 1em;
  }
}

@media only screen and (max-width: 900px) {
  
  .menuContainer{
    column-gap: 7em;
    font-size: 0.95em;
  }
}

@media only screen and (max-width: 650px) {
  
  .menuContainer{
    column-gap: 5em;
    font-size: 0.85em;
  }
}

@media only screen and (max-width: 440px) {
  .menuContainer{
    column-gap: 2em;
    font-size: 0.75em;
  }
}

@media only screen and (max-width: 320px) {
  .menuContainer{
    column-gap: 1em;
    font-size: 0.65em;
  }
}

@media only screen and (max-width: 270px) {
  .menuContainer{
    column-gap: 0.7em;
    font-size: 0.6em;
  }
}

@media only screen and (max-width: 240px) {
  .menuContainer{
    font-size: 0.6em;
  }
}
