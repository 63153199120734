.socialRow{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 2em;
  align-items: center;
}
.socialLink{
  transform: scale(0.9);
  fill: antiquewhite;
}

@media only screen and (max-width: 630px) {
  .socialLink{
    transform: scale(0.78);
  }
}
