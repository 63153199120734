.cardContainer {
    display: flex;
    flex-direction: column;
    gap: 1em;
    align-items: center;
    text-align: center;

}

.cardImg{
    object-fit: cover;
    border-radius: 0.53em;

    /* border: solid rgb(172, 19, 19);
    border-width: 0.2em 0.2em; */

    width: 13em;
    height: 13em;

    image-rendering: -moz-crisp-edges;         /* Firefox */
    image-rendering:   -o-crisp-edges;         /* Opera */
    image-rendering: -webkit-optimize-contrast;/* Webkit (non-standard naming) */
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;  /* IE (non-standard property) */
}

.cardId {
    font-weight: 800;  
    font-size: 1.2em;
}

@media only screen and (max-width: 1700px) {
    .cardImg{
        width: 11em;
        height: 11em;
    }
}

@media only screen and (max-width: 900px) {
    .cardContainer{
        gap: 0.7em;
    }

    .cardId{
        font-size: 0.9em;
    }

    .cardImg{
        width: 9em;
        height: 9em;
    }
}

@media only screen and (max-width: 700px) {
    .cardContainer{
        gap: 0.5em;
    }

    .cardId{
        font-size: 0.8em;
    }

    .cardImg{
        width: 7em;
        height: 7em;
    }
}

@media only screen and (max-width: 500px) {
    .cardContainer{
        gap: 0.5em;
    }

    .cardId{
        font-size: 0.7em;
    }

    .cardImg{
        width: 6em;
        height: 6em;
    }
}

@media only screen and (max-width: 400px) {
    .cardContainer{
        gap: 0.3em;
    }

    .cardId{
        font-size: 0.65em;
    }

    .cardImg{
        width: 5em;
        height: 5em;
    }
}