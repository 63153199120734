.presentationContainer{
  display: flex;
  flex-direction: column;
  justify-content:center;
  align-items: center;
  gap: 3em;
  color: antiquewhite;
  font-family: 'JetBrains Mono', monospace;
  padding-bottom: 5em;
  padding-left: 4em;
  padding-right: 4em;
}
.mainPresentationContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content:center;
  align-items: center;
  gap: 5em;
  padding-top: 6em;
}
.presentationTextContainer {
  width: 36em;
}
.presentationTextTitle {
  font-weight:bolder;
  font-size: 2.2em;
  font-style: italic;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: antiquewhite;
  padding-bottom: 1em;
  border-bottom: 0.1em solid antiquewhite;
  color: rgb(56, 127, 139);
  /* color: rgb(156, 12, 12) */

}
.presentationText {
  text-align: justify;
  padding-top: 2em;
  font-size: 1em;
  font-family: 'JetBrains Mono', monospace;
  color: antiquewhite;
}

.presentationImg {
  height: 20em;
  border-radius: 2em;
  border: 0.15em solid antiquewhite;
  /* width: 20em; */
}

.distribContainer{
  display: flex;
  flex-direction: column;
  justify-content:center;
  gap: 1.5em;
  width: 61em;
}
.distribTitle{
  font-size: 1.5em;
  font-style: italic;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: bold;
  color: rgb(56, 127, 139);
  /* color: rgb(156, 12, 12) */
}
.distribText{
  text-align: justify;
}


@media only screen and (max-width: 1076px) {
  .presentationContainer{
    gap: 2em;
  }
  .mainPresentationContainer{
    gap: 3em;
    padding-top: 4em;
  }
  
  .distribContainer {
    width: 36em;
  }
  .presentationText{
    font-size: 0.95em;
  }
  .presentationTextTitle{
    font-size: 2em;
  }
  .distribTitle{
    font-size: 1.4em;
  }
  .distribText{
    font-size: 0.95em;
  }
}

@media only screen and (max-width: 630px) {
  .presentationContainer{
    gap: 2.3em;
  }
  .mainPresentationContainer{
    gap: 2.3em;
    padding-top: 3em;
  }
  .presentationTextContainer {
    width: 25em;
  }
  .distribContainer {
    width: 25em;
    gap: 1em;
  }
  .presentationText{
    font-size: 0.8em;
  }
  .presentationTextTitle{
    font-size: 1.2em;
  }
  .distribTitle{
    font-size: 1em;
  }
  .distribText{
    font-size: 0.8em;
  }
  .presentationImg {
    height: 15em;
    border: 0.13em solid antiquewhite;
  }
}

@media only screen and (max-width: 440px) {
  .presentationContainer{
    gap: 1em;
  }
  .mainPresentationContainer{
    gap: 1em;
    padding-top: 2em;
  }
  .presentationTextContainer {
    width: 22em;
  }
  .distribContainer {
    width: 22em;
    gap: 0.5em;
  }
  .presentationText{
    font-size: 0.6em;
  }
  .presentationTextTitle{
    font-size: 0.9em;
  }
  .distribTitle{
    font-size: 0.7em;
  }
  .distribText{
    font-size: 0.6em;
  }
  .presentationImg {
    height: 10em;
    border: 0.09em solid antiquewhite;
  }
}

@media only screen and (max-width: 370px) {
  .presentationContainer{
    gap: 1em;
  }
  .mainPresentationContainer{
    gap: 0.8em;
    padding-top: 2em;
  }
  .presentationTextContainer {
    width: 18em;
  }
  .distribContainer {
    width: 18em;
    gap: 0.5em;
  }
  .presentationText{
    font-size: 0.45em;
  }
  .presentationTextTitle{
    font-size: 0.7em;
  }
  .distribTitle{
    font-size: 0.55em;
  }
  .distribText{
    font-size: 0.45em;
  }
  .presentationImg {
    height: 7em;
    width: 7em;
    border: 0.08em solid antiquewhite;
  }
}

@media only screen and (max-width: 330px) {
  .presentationContainer{
    gap: 1em;
  }

  .mainPresentationContainer{
    gap: 0.7em;
    padding-top: 1em;
  }
  .presentationTextContainer {
    width: 16em;
  }
  .distribContainer {
    width: 16em;
    gap: 0.45em;
  }
  .presentationText{
    font-size: 0.4em;
  }
  .presentationTextTitle{
    font-size: 0.65em;
  }
  .distribTitle{
    font-size: 0.5em;
  }
  .distribText{
    font-size: 0.4em;
  }
  .presentationImg {
    height: 5em;
    width: 5em;
    border: 0.001em solid antiquewhite;
  }
}

@media only screen and (max-width: 290px) {
  .presentationContainer{
    gap: 0.8em;
  }

  .mainPresentationContainer{
    gap: 0.5em;
    padding-top: 1em;
  }
  .presentationTextContainer {
    width: 14em;
  }
  .distribContainer {
    width: 14em;
    gap: 0.45em;
  }
  .presentationText{
    font-size: 0.4em;
  }
  .presentationTextTitle{
    font-size: 0.65em;
  }
  .distribTitle{
    font-size: 0.5em;
  }
  .distribText{
    font-size: 0.4em;
  }
  .presentationImg {
    height: 5em;
    width: 5em;
    border: 0.001em solid antiquewhite;
  }
}

@media only screen and (max-width: 250px) {
  .presentationContainer{
    gap: 0.8em;
  }

  .mainPresentationContainer{
    gap: 0.5em;
    padding-top: 1em;
  }
  .presentationTextContainer {
    width: 12em;
  }
  .distribContainer {
    width: 12em;
    gap: 0.45em;
  }
  .presentationText{
    font-size: 0.4em;
  }
  .presentationTextTitle{
    font-size: 0.65em;
  }
  .distribTitle{
    font-size: 0.5em;
  }
  .distribText{
    font-size: 0.4em;
  }
  .presentationImg {
    height: 4em;
    width: 4em;
    /* border: 0.001em solid antiquewhite; */
  }
}