.cardsContainer{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-top: 2em;
    gap: 1.5em;
    justify-content: center;
    align-items: center;
    color:antiquewhite;
    padding-left: 3em;
    padding-right: 3em;
}

.cardAndButtonContainer{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5em;
    padding-bottom: 2em;
}

.buttonContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 0.5em;
}

.lockedContainer, .unlockedContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.8em;
    font-size: 0.9em;
}

.pendingRewardsContainer{
    display: flex;
    flex-direction: row;
}

.pendingRewardsContainer img {
    width: 1.2em;
    height: 1.2em;
}

.lockButton, .harvestButton, .unlockButton, .harvestAndUnlockButton {
    background-color: antiquewhite;
    font-size: 1em;
    border-radius: 0.33em;
    height: 1.8em;
    width: 5em;
    cursor:pointer;
    border: solid 0.1em antiquewhite; 
}

.harvestAndUnlockButton {
    height: 3.7em;
    width: 5em;
}

.selectTimeframe{
    background-color: antiquewhite;
    font-size: 1em;
    border-radius: 0.33em;
    height: 1.8em;
    width: 5.5em;
    cursor:pointer;
}


@media only screen and (max-width: 900px) {
    .cardsContainer{
        padding-top: 1em;
        gap: 2em;
        padding-left: 2em;
        padding-right: 2em;
    }

    .lockedContainer, .unlockedContainer{
        gap: 0.4em;
        font-size: 0.8em;
    }

    .cardAndButtonContainer{
        gap: 0.4em;
        padding-bottom: 1em;
    }

    .selectTimeframe, .lockButton{
        font-size: 0.8em;
        height: 1.7em;
        width: 4.2em;
    }
}

@media only screen and (max-width: 700px) {
    .cardsContainer{
        padding-top: 1em;
        gap: 2em;
        padding-left: 2em;
        padding-right: 2em;
    }

    .lockedContainer, .unlockedContainer{
        gap: 0.4em;
        font-size: 0.75em;
    }

    .cardAndButtonContainer{
        gap: 0.4em;
        padding-bottom: 0em;
    }

    .selectTimeframe, .lockButton{
        font-size: 0.7em;
        height: 1.5em;
        width: 4em;
    }
}

@media only screen and (max-width: 500px) {
    .cardsContainer{
        padding-top: 0.5em;
        gap: 1.6em;
        padding-left: 1em;
        padding-right: 1em;
    }

    .lockedContainer, .unlockedContainer{
        gap: 0.3em;
        font-size: 0.6em;
    }
    .cardAndButtonContainer{
        gap: 0.3em;
        padding-bottom: 0em;
    }
}