.navbarContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* align-items: center; */
  column-gap: 10em;
}
.navbarlink, .navbarlink:hover, .navbarlink:active, .navbarlink:visited{
  text-decoration: none;
  color: antiquewhite;
}

.tradelink {
  text-decoration: none;
  color: antiquewhite;
  /* border-bottom: 0.15em solid antiquewhite; */
  /* font-style: normal; */
  cursor: pointer;
}

.tradelinkDropdown {
  padding-top: 0.4em;
  display: flex;
  flex-direction: column;
  gap: 0.2em;
  position: absolute;
  /* height: 5em; */
  
  /* top: 4em; */
  /* border-bottom: 0.15em solid antiquewhite; */

}

.navbarlink:hover {
  border-bottom: 0.15em solid antiquewhite;
}

@media only screen and (max-width: 1000px) {
  .navbarContainer{
    column-gap: 6em;
  }
}
@media only screen and (max-width: 630px) {
  .navbarContainer{
    column-gap: 4em;
  }
}

@media only screen and (max-width: 560px) {
  .navbarContainer{
    column-gap: 2em;
  }
}

@media only screen and (max-width: 440px) {
  .navbarContainer{
    column-gap: 1.6em;
  }
}

@media only screen and (max-width: 370px) {
  .navbarContainer{
    column-gap: 1.3em;
  }
}

@media only screen and (max-width: 320px) {
  .navbarContainer{
    column-gap: 1.1em;
  }
}

@media only screen and (max-width: 270px) {
  .navbarContainer{
    column-gap: 0.8em;
  }
}
