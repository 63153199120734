.StakeContainer {
  padding-top: 4em;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  gap: 3em;
  color: antiquewhite;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.MyNftsContainer, .MyLockedNftsContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* border: 0.2em solid antiquewhite;
  border-radius: 1.7em; */
  /* padding-top: 1.5em;
  padding-bottom: 1.5em; */
  margin-left: 15em;
  margin-right: 15em;
  width: 78%;
}

.MyNftsTitle, .MyLockedNftsTitle{
  color: antiquewhite;
  font-size: 2em;
  font-weight: 1000;
}

.MyNftsDescription{
  display: flex;
  flex-direction: column;
  gap: 0.7em;
  font-size: 1.2em;
}

.MyNftsDescriptionTitle{
  font-size: 1.4em;
  font-weight: 600;
}

.MyNftsDescriptionTimeCostContainer{
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 0.3em;
}
.MyNftsDescriptionTimeCost, .BloodBalanceContainer{
  display: flex;
  gap: 5em;
  justify-content: space-between;
  align-items: center;
}

.BloodBalanceValueTextContainer, .MyNftsDescriptionCostValueImgText {
  display: flex;
  flex-direction: row;
  gap: 0.2em;
  align-items: center;
}

.BloodBalanceValueTextContainer img, .MyNftsDescriptionCostValueImgText img  {
  width: 1.5em;
  height: 1.5em;
}

.walletAdrsValue {
  font-weight: 800;
}

@media only screen and (max-width: 1700px) {
  .StakeContainer {
    padding-top: 3.5em;
  }

  .MyNftsDescription{
    gap: 1.4em;
    font-size: 1.1em;
  }
  .MyNftsTitle, .MyLockedNftsTitle{
    font-size: 1.8em;
  }
  .MyNftsContainer, .MyLockedNftsContainer{
    border:none;
  }
}

@media only screen and (max-width: 900px) {
  .StakeContainer {
    padding-top: 3.5em;
    gap: 1.5em;
  }

  .MyNftsDescription{
    gap: 1.4em;
    font-size: 0.95em;
  }
  .MyNftsTitle, .MyLockedNftsTitle{
    font-size: 1.5em;
  }

  .MyNftsContainer, .MyLockedNftsContainer{
    border:none;
  }
}

@media only screen and (max-width: 700px) {
  .StakeContainer {
    padding-top: 3em;
    gap: 2em;
  }
  .MyNftsDescription{
    gap: 0.7em;
    font-size: 0.8em;
  }

  .MyNftsTitle, .MyLockedNftsTitle{
    font-size: 1.3em;
  }
}

@media only screen and (max-width: 500px) {
  .StakeContainer {
    padding-top: 2em;
    gap: 2em;
  }
  .MyNftsDescription{
    gap: 0.5em;
    font-size: 0.7em;
    padding-left: 3em;
    padding-right: 3em;
  }
  .MyNftsDescriptionTitle{
    font-size: 1.3em;
  }
  .MyNftsTitle, .MyLockedNftsTitle{
    font-size: 1.2em;
  }
}

@media only screen and (max-width: 400px) {
  .MyNftsDescription{
    gap: 0.5em;
    font-size: 0.6em;
    padding-left: 2em;
    padding-right: 2em;
  }
  .MyNftsDescriptionTitle{
    font-size: 1em;
  }
  .MyNftsTitle, .MyLockedNftsTitle{
    font-size: 1em;
  }
}

@media only screen and (max-width: 250px) {
  .MyNftsDescription{
    gap: 0.5em;
    font-size: 0.5em;
    padding-left: 1em;
    padding-right: 1em;
  }
  .MyNftsDescriptionTitle{
    font-size: 0.9em;
  }
  .MyNftsTitle, .MyLockedNftsTitle{
    font-size: 0.9em;
  }
}
